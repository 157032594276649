import axios from 'axios';
import classNames from 'classnames';
import { useState, useMemo, useEffect, useCallback, useRef } from 'react';

import { useSelector } from 'src/store';
import { selectedSchemeIdSelector } from 'src/store/selectors/schemeSelector';

import { useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormErrors } from '@itm/shared-frontend/lib/hooks';

import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { PageLoading } from '@itm/shared-frontend/lib/components';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Download, ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import { DataAccessPermission, isDataAccessError } from '@itm/shared-frontend/lib/components/dataAccess';
import useDataAccessPermission from 'src/hooks/useDataAccessPermission';

import { clientPortalApi } from 'src/api';
import {
  getReport,
  getReportNameListAll,
  getProductTypeListBySchemeId,
  getMainClassList,
} from 'src/api/eArchive/report';

import { ReportName, DownloadReportDto, SelectOption, ServerFormErrors, ServerError } from 'src/types';

import styles from './Reports.module.scss';

function Reports() {
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);
  const [reportNameOptions, setReportNameOptions] = useState<SelectOption[] | undefined>([]);
  const [productTypeOptions, setProductTypeOptions] = useState<SelectOption[] | undefined>([]);
  const [mainClassesOptions, setMainClassesOptions] = useState<SelectOption[] | undefined>([]);
  const [isLoading, setIsLoading] = useState(true);
  const mainClassesOptionsCacheRef = useRef<Partial<Record<string, SelectOption[]>>>({});

  const {
    companyId,
    companyName,
    productId,
    hasDataAccessToScheme,
    hasDataAccess,
    isShowDataAccessForm,
    setHasDataAccess,
    setIsShowDataAccessForm,
    requestSchemeList,
    resetDataAccessState,
  } = useDataAccessPermission();

  const getOptionLabelByReportId = useCallback(
    (reportId: string) => {
      if (!reportNameOptions) return '';
      const reportOption = reportNameOptions.find(({ value }) => value === reportId);
      if (!reportOption) return '';
      return reportOption.label;
    },
    [reportNameOptions],
  );

  const formSchema = useMemo(
    () =>
      object().shape({
        reportId: string().label('Project type').trim().required(),
        policyNumbers: string().when('reportId', {
          is: (reportId: string) => {
            const reportName = getOptionLabelByReportId(reportId);
            return reportName === ReportName.PolicyDetails;
          },
          // Policy Details Report
          then: (schema) =>
            schema.when(['productTypes', 'mainClasses'], {
              is: (productTypes?: string[], mainClasses?: string[]) =>
                (productTypes && productTypes.length) || (mainClasses && mainClasses.length),
              // productTypes or mainClasses selected
              then: (schema) => schema.label('Policy numbers').trim().optional().maxLines(300),
              // productTypes and mainClasses empty
              otherwise: (schema) =>
                schema
                  .label('Policy numbers')
                  .trim()
                  .required('At least 1 policy number or 1 main class or 1 product type should be specified')
                  .maxLines(300),
            }),
          // Not Policy Details Report
          otherwise: (schema) =>
            schema.when('reportId', {
              is: (reportId: string) => {
                const reportName = getOptionLabelByReportId(reportId);
                return reportName === ReportName.Transactions;
              },
              // Transactions Report
              then: (schema) =>
                schema.when('mainClasses', {
                  is: (mainClasses?: string[]) => mainClasses && mainClasses.length,
                  // mainClasses selected
                  then: (schema) => schema.label('Policy numbers').trim().optional().maxLines(300),
                  // mainClasses empty
                  otherwise: (schema) =>
                    schema
                      .label('Policy numbers')
                      .trim()
                      .required('At least 1 policy number or 1 main class should be specified')
                      .maxLines(300),
                }),
              // Not Policy Details or Transactions Report
              otherwise: (schema) =>
                schema.when('reportId', {
                  is: (reportId: string) => {
                    const reportName = getOptionLabelByReportId(reportId);
                    return reportName === ReportName.SumAssured || reportName === ReportName.Ratings;
                  },
                  // Sum Assured or Ratings Report
                  then: (schema) => schema.trim().optional(), // hidden
                  // Not Policy Details, Transactions, Sum Assured or Ratings Report = Comments Report
                  otherwise: (schema) => schema.label('Policy numbers').trim().required().maxLines(300),
                }),
            }),
        }),
        productTypes: array().when('reportId', {
          is: (reportId: string) => {
            const reportName = getOptionLabelByReportId(reportId);
            return reportName === ReportName.PolicyDetails;
          },
          // Policy Details Report
          then: (schema) =>
            schema.when(['policyNumbers', 'mainClasses'], {
              is: (policyNumbers?: string, mainClasses?: string[]) =>
                policyNumbers || (mainClasses && mainClasses.length),
              // policyNumbers or mainClasses selected
              then: (schema) => schema.label('Product types').min(0),
              // policyNumbers and mainClasses empty
              otherwise: (schema) =>
                schema
                  .label('Product types')
                  .min(1, 'At least 1 policy number or 1 main class or 1 product type should be specified'),
            }),
          // Not Policy Details Report
          otherwise: (schema) => schema.optional(), // hidden
        }),
        mainClasses: array().when('reportId', {
          is: (reportId: string) => {
            const reportName = getOptionLabelByReportId(reportId);
            return reportName === ReportName.PolicyDetails;
          },
          // Policy Details Report
          then: (schema) =>
            schema.when(['policyNumbers', 'productTypes'], {
              is: (policyNumbers?: string, productTypes?: string[]) =>
                policyNumbers || (productTypes && productTypes.length),
              // policyNumbers or productTypes selected
              then: (schema) => schema.label('Main classes').min(0),
              // policyNumbers and productTypes empty
              otherwise: (schema) =>
                schema
                  .label('Main classes')
                  .min(1, 'At least 1 policy number or 1 main class or 1 product type should be specified'),
            }),
          // Not Policy Details Report
          otherwise: (schema) =>
            schema.when('reportId', {
              is: (reportId: string) => {
                const reportName = getOptionLabelByReportId(reportId);
                return reportName === ReportName.Transactions;
              },
              // Transactions Report
              then: (schema) =>
                schema.when('policyNumbers', {
                  is: (policyNumbers?: string) => !!policyNumbers,
                  // policyNumbers selected
                  then: (schema) => schema.label('Main classes').min(0),
                  // policyNumbers empty
                  otherwise: (schema) =>
                    schema.label('Main classes').min(1, 'At least 1 policy number or 1 main class should be specified'),
                }),
              // Not Policy Details or Transactions Report
              otherwise: (schema) => schema.label('Main classes').min(0),
            }),
        }),
        // mainClasses: array().label('Main classes').min(0),
        startDate: string().label('Start date').trim(),
        endDate: string().label('End date').trim(),
      }),
    [getOptionLabelByReportId],
  );

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors, watch, trigger } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { serverErrorMessages, handleErrors } = useFormErrors(setError, clearErrors);
  const [globalServerErrorMessages, setGlobalServerErrorMessages] = useState<ServerFormErrors>([]);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const [reportId, productTypes, mainClasses, policyNumbers] = watch([
    'reportId',
    'productTypes',
    'mainClasses',
    'policyNumbers',
  ]);
  const reportName = useMemo(() => getOptionLabelByReportId(reportId), [getOptionLabelByReportId, reportId]);

  const fillReportNameOptions = useCallback(async () => {
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setReportNameOptions(undefined);
    try {
      const res = await getReportNameListAll({ signal: abortController.signal });
      const options: SelectOption[] = res.data.map(({ id, name }) => ({ label: name, value: id }));
      setReportNameOptions(options);
    } catch (e) {
      if (e instanceof axios.Cancel || !(e instanceof axios.AxiosError)) return;

      if (isDataAccessError(e.response)) {
        setHasDataAccess(false);
      } else {
        const serverErrors = new ServerErrorAdapter(e);
        setGlobalServerErrorMessages(serverErrors.combine());
      }
      setReportNameOptions([]);
    } finally {
      abortControllerSet.delete(abortController);
    }
  }, [abortControllerSet, setHasDataAccess]);

  const fillProductTypeOptions = useCallback(async () => {
    if (!selectedSchemeId || !hasDataAccessToScheme || !hasDataAccess) return;
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setProductTypeOptions(undefined);
    try {
      const res = await getProductTypeListBySchemeId(selectedSchemeId, { signal: abortController.signal });
      const options: SelectOption[] = res.data.map((value) => ({ label: value, value: value }));
      setProductTypeOptions(options);
    } catch (e) {
      if (e instanceof axios.Cancel || !(e instanceof axios.AxiosError)) return;

      if (isDataAccessError(e.response)) {
        setHasDataAccess(false);
      } else {
        const serverErrors = new ServerErrorAdapter(e);
        setGlobalServerErrorMessages(serverErrors.combine());
      }
      setProductTypeOptions([]);
    } finally {
      abortControllerSet.delete(abortController);
    }
  }, [selectedSchemeId, hasDataAccess, hasDataAccessToScheme, setHasDataAccess, abortControllerSet]);

  const fillMainClassesOptions = useCallback(
    async (reportId: string) => {
      if (!selectedSchemeId || !hasDataAccessToScheme || !hasDataAccess) return;
      if (mainClassesOptionsCacheRef.current[reportId]) {
        setMainClassesOptions(mainClassesOptionsCacheRef.current[reportId]);
        return;
      }
      const abortController = new AbortController();
      abortControllerSet.add(abortController);
      setMainClassesOptions(undefined);
      try {
        const params = { reportId, schemeId: selectedSchemeId };
        const config = { signal: abortController.signal };
        const res = await getMainClassList(params, config);
        const options: SelectOption[] = res.data.map((value) => ({ label: value, value: value }));
        mainClassesOptionsCacheRef.current[reportId] = options;
        setMainClassesOptions(options);
      } catch (e) {
        if (e instanceof axios.Cancel || !(e instanceof axios.AxiosError)) return;

        if (isDataAccessError(e.response)) {
          setHasDataAccess(false);
        } else {
          const serverErrors = new ServerErrorAdapter(e);
          setGlobalServerErrorMessages(serverErrors.combine());
        }
        setMainClassesOptions([]);
      } finally {
        abortControllerSet.delete(abortController);
      }
    },
    [selectedSchemeId, hasDataAccess, hasDataAccessToScheme, setHasDataAccess, abortControllerSet],
  );

  const onSubmit = handleSubmit(
    async ({ policyNumbers: policyNumbersText = '', reportId, productTypes, mainClasses, startDate, endDate }) => {
      if (!hasDataAccessToScheme || !hasDataAccess) return;
      try {
        handleErrors();
        const schemeId = selectedSchemeId;
        const policyNumbers = policyNumbersText.trim().split(/[\s\b]+/g);

        let data: DownloadReportDto;

        switch (reportName) {
          case ReportName.PolicyDetails:
            data = { schemeId, reportId, policyNumbers, productTypes, mainClasses };
            break;
          case ReportName.Transactions:
            data = { schemeId, reportId, policyNumbers, mainClasses, startDate, endDate };
            break;
          case ReportName.Comments:
            data = { schemeId, reportId, policyNumbers };
            break;
          case ReportName.SumAssured:
          case ReportName.Ratings:
          default:
            data = { schemeId, reportId };
        }

        const res = await getReport(data);
        Download.fileFromRes(res, `${reportName} Report.csv`);
      } catch (e) {
        if (!(e instanceof axios.AxiosError)) return;

        if (isDataAccessError(e.response)) {
          setHasDataAccess(false);
          return;
        } else if (e.response?.data instanceof Blob) {
          const jsonErrorRes = new ServerErrorAdapter(e).combine()[0];
          let customError;
          try {
            const errorsRes = JSON.parse(jsonErrorRes);
            customError = { response: { data: errorsRes } };
          } catch {
            customError = { message: 'Unknown error' };
          }
          handleErrors(customError as ServerError);
        } else {
          handleErrors(e);
        }
      }
    },
  );

  const init = useCallback(async () => {
    setIsLoading(true);
    await fillReportNameOptions();
    setIsLoading(false);
  }, [fillReportNameOptions]);

  useEffect(() => {
    init();
  }, [init]);

  // Revalidate policyNumbers
  useEffect(() => {
    if (formState.isSubmitted || formState.isSubmitting) {
      trigger('policyNumbers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, productTypes, mainClasses]);

  // Revalidate productTypes
  useEffect(() => {
    if (formState.isSubmitted || formState.isSubmitting) {
      trigger('productTypes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyNumbers, mainClasses]);

  // Revalidate mainClasses
  useEffect(() => {
    if (formState.isSubmitted || formState.isSubmitting) {
      trigger('mainClasses');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, policyNumbers, productTypes]);

  useEffect(() => {
    if (reportName === ReportName.PolicyDetails) {
      setValue('productTypes', []);
      fillProductTypeOptions();
    }
    if (reportName === ReportName.PolicyDetails || reportName === ReportName.Transactions) {
      setValue('mainClasses', []);
      fillMainClassesOptions(reportId);
    }
  }, [reportId, reportName, fillMainClassesOptions, fillProductTypeOptions, setValue]);

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  useEffect(() => {
    setGlobalServerErrorMessages([]);
  }, [companyId]);

  useEffect(() => () => cleanup(), [cleanup]);

  const SuccessDataAccessButtonComponent = useMemo(
    () => () => {
      const clickHandler = async () => {
        await requestSchemeList();
        resetDataAccessState();
      };
      return (
        <button className="button is-interact" type="button" onClick={clickHandler}>
          Back to Reports
        </button>
      );
    },
    [requestSchemeList, resetDataAccessState],
  );

  if (isLoading) return <PageLoading />;

  return (
    <>
      <ServerErrorMessages messages={globalServerErrorMessages} />
      {(!hasDataAccessToScheme || !hasDataAccess) && (
        <DataAccessPermission
          companyId={companyId}
          companyName={companyName}
          clientPortalApiInstance={clientPortalApi}
          productId={productId}
          messageButtonOnClick={() => setIsShowDataAccessForm(true)}
          SuccessButtonComponent={SuccessDataAccessButtonComponent}
        />
      )}
      {!isShowDataAccessForm && (
        <form className="mb-5" name="projectForm" onSubmit={onSubmit} noValidate>
          <div className="mb-5">
            {/* Row 1 */}
            <div className="columns is-multiline">
              <div className="column is-3-desktop is-6-tablet">
                <Field
                  inputClassName={classNames('is-fullwidth', { 'is-loading': reportNameOptions === undefined })}
                  label="Report Name"
                  field="dropdown"
                  placeholder={
                    reportNameOptions === undefined || !reportNameOptions.length
                      ? 'No available report names'
                      : 'Report Name'
                  }
                  options={reportNameOptions}
                  autoComplete="off"
                  register={register('reportId')}
                  control={control}
                  formSchema={formSchema}
                  errors={formState.errors}
                  disabled={reportNameOptions === undefined || !reportNameOptions.length}
                />
              </div>

              {reportName && !(reportName === ReportName.SumAssured || reportName === ReportName.Ratings) && (
                <div className="column is-3-desktop is-6-tablet">
                  <Field
                    inputClassName={classNames('is-fullwidth', styles.HighTextArea)}
                    label="Policy Numbers"
                    field="textarea"
                    placeholder="Policy Numbers"
                    autoComplete="off"
                    register={register('policyNumbers')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                  />
                </div>
              )}

              {reportName === ReportName.PolicyDetails && (
                <div className="column is-3-desktop is-6-tablet">
                  <Field
                    inputClassName={classNames('is-fullwidth', { 'is-loading': productTypeOptions === undefined })}
                    label="Product Types"
                    field="dropdown"
                    placeholder={
                      productTypeOptions === undefined || !productTypeOptions.length
                        ? 'No available product types'
                        : 'Product Types'
                    }
                    options={productTypeOptions}
                    autoComplete="off"
                    register={register('productTypes')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    readOnly={productTypeOptions === undefined || !productTypeOptions.length}
                    defaultValue={[]}
                    multiple
                  />
                </div>
              )}

              {(reportName === ReportName.PolicyDetails || reportName === ReportName.Transactions) && (
                <div className="column is-3-desktop is-6-tablet">
                  <Field
                    inputClassName={classNames('is-fullwidth', { 'is-loading': mainClassesOptions === undefined })}
                    label="Main Classes"
                    field="dropdown"
                    placeholder={
                      mainClassesOptions === undefined || !mainClassesOptions.length
                        ? 'No available main classes'
                        : 'Main Classes'
                    }
                    options={mainClassesOptions}
                    autoComplete="off"
                    register={register('mainClasses')}
                    control={control}
                    formSchema={formSchema}
                    errors={formState.errors}
                    readOnly={mainClassesOptions === undefined || !mainClassesOptions.length}
                    defaultValue={[]}
                    multiple
                  />
                </div>
              )}

              {reportName === ReportName.Transactions && (
                <>
                  <div className="column is-3-desktop is-6-tablet">
                    <Field
                      inputClassName="is-fullwidth"
                      label="Transaction Start Date"
                      field="datepicker"
                      placeholder="dd/mm/yyyy"
                      autoComplete="off"
                      register={register('startDate')}
                      control={control}
                      formSchema={formSchema}
                      errors={formState.errors}
                    />
                    <Field
                      inputClassName="is-fullwidth"
                      label="Transaction End Date"
                      field="datepicker"
                      placeholder="dd/mm/yyyy"
                      autoComplete="off"
                      register={register('endDate')}
                      control={control}
                      formSchema={formSchema}
                      errors={formState.errors}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="has-text-right">
            <div className="field">
              <LoadingButton
                className="button is-interact"
                type="submit"
                isLoading={formState.isSubmitting}
                disabled={!reportId || !mainClassesOptions || !hasDataAccessToScheme || !hasDataAccess}
              >
                Download
              </LoadingButton>
              <ServerErrorMessages messages={serverErrorMessages} />
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Reports;
