import classNames from 'classnames';

import { lazy, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GuardedRoute } from 'src/router';

import { useSelector } from 'src/store';
import { authRoleSelector } from 'src/store/selectors/authSelector';
import { productInfoSelector } from 'src/store/selectors/productSelector';

import { TabLayout } from '@itm/shared-frontend/lib/components/layout';
import type { TabOption } from '@itm/shared-frontend/lib/components/layout/TabLayout';

import PolicySearchPage from './policy/PolicySearch';
import PolicyDetailsPage from './policy/PolicyDetails';

import ReportsPage from './reports/Reports';

const UserAuditPage = lazy(() => import('./userAudit/UserAudit'));
// const LogsPage = lazy(() => import('./logs/Logs'));
const ProductInfoPage = lazy(() => import('./productInfo/ProductInfo'));

import { Role } from 'src/types';

import styles from './index.module.scss';

function Landing() {
  const userRole = useSelector(authRoleSelector);
  const { description } = useSelector(productInfoSelector);

  const tabLayoutOptions = useMemo<TabOption[]>(
    () =>
      [
        {
          path: 'policy/search',
          tabTitle: 'Search',
          allowRoleList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager],
        },
        {
          path: 'reports',
          tabTitle: 'Reports',
          allowRoleList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager],
        },
        {
          path: 'user-audit',
          tabTitle: 'User Audit',
          allowRoleList: [Role.SuperAdmin, Role.Support],
        },

        // {
        //   path: 'logs',
        //   tabTitle: 'Logs',
        //   allowRoleList: [Role.SuperAdmin, Role.Support],
        // },
        {
          path: 'info',
          tabTitle: 'Info',
          allowRoleList: [Role.SuperAdmin, Role.Support],
        },
      ]
        .filter(({ allowRoleList }) => allowRoleList.includes(userRole!))
        .map(({ path, tabTitle }) => ({ path, tabTitle })),
    [userRole],
  );

  return (
    <>
      <div className="px-3">
        <div className="container">
          <div className="container px-2 is-flex is-flex-grow-0 is-flex-direction-column is-align-items-center">
            <p className={classNames(styles.Description, 'pt-5 mb-6 is-size-5 has-text-centered')}>{description}</p>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="container">
          <TabLayout tabLayoutOptions={tabLayoutOptions}>
            <Routes>
              <Route index element={<Navigate to={tabLayoutOptions[0].path} replace={true} />} />

              <Route
                path="policy/search"
                element={
                  <GuardedRoute
                    meta={{ role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager] } }}
                  >
                    <PolicySearchPage />
                  </GuardedRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <GuardedRoute
                    meta={{ role: { allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager] } }}
                  >
                    <ReportsPage />
                  </GuardedRoute>
                }
              />
              <Route
                path="user-audit"
                element={
                  <GuardedRoute meta={{ role: { allowList: [Role.SuperAdmin, Role.Support] } }}>
                    <UserAuditPage />
                  </GuardedRoute>
                }
              />
              {/* <Route
                path="logs"
                element={
                  <GuardedRoute meta={{ role: { allowList: [Role.SuperAdmin, Role.Support] } }}>
                    <LogsPage />
                  </GuardedRoute>
                }
              /> */}
              <Route
                path="info"
                element={
                  <GuardedRoute meta={{ role: { allowList: [Role.SuperAdmin, Role.Support] } }}>
                    <ProductInfoPage />
                  </GuardedRoute>
                }
              />

              <Route path="*" element={<Navigate to="./" />} />
            </Routes>
          </TabLayout>
        </div>
      </div>
    </>
  );
}

function LandingRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="console" replace={true} />} />

      {/* Landing */}
      <Route
        path="console/*"
        element={
          <GuardedRoute meta={{ auth: true }}>
            <Landing />
          </GuardedRoute>
        }
      />
      {/* RoutePath.landingPolicyDetails */}
      <Route
        path="policy/:policyNumber/details"
        element={
          <GuardedRoute meta={{ auth: true }}>
            <PolicyDetailsPage />
          </GuardedRoute>
        }
      />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default LandingRoutes;
